/**
 * CGW Consulting
 */

.services-section {
    padding: 4rem 0;
}

.service-card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
}

.service-card:hover {
    transform: translateY(-5px);
}