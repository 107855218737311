/**
 * CGW Consulting
 */

.flag {
    cursor: pointer;
    margin-right: 8px;
    transition: opacity 0.3s ease;
}

.flag-inactive {
    opacity: 0.35;
}