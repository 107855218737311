/**
 * CGW Consulting
 */


.hero {
    text-align: center;
    padding: 5rem 0;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.hero-content {
    max-width: 80%;
}

.hero h1 {
    margin-bottom: 1rem;
    font-size: 2.8rem;
    color: #007bff;
    font-weight: bold;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.hero p {
    color: #000;
    margin-bottom: 2rem;
    font-size: 1.4rem;
    line-height: 1.6;
}

.cta-button {
    padding: 1rem 2rem;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 50px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 1px;
    transition: all 0.3s ease;
    outline: none;
}

.cta-button:hover,
.cta-button:focus {
    background-color: #0056b3;
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
    .hero h1 {
        font-size: 2rem;
    }

    .hero p {
        font-size: 1rem;
    }

    .cta-button {
        padding: 0.8rem 1.6rem;
    }
}