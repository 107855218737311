/**
 * CGW Consulting
 */

.about-section {
    padding: 4rem 0;
    background-color: #f8f9fa;
}

.about-image {
    margin-bottom: 2rem;
}

.about-image img {
    max-width: 50%;
    height: auto;
    border-radius: 50%;
}

.about-text {
    color: #333;
}

.about-heading h2 {
    color: #007bff;
    margin-bottom: 1rem;
}

.about-heading p {
    font-weight: 300;
    color: #666;
}

@media (max-width: 768px) {

    .about-image,
    .about-text {
        text-align: center;
    }

    .about-image {
        margin-bottom: 1rem;
    }
}